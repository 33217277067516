
import React, { useEffect, useState } from "react";
import "./header.css";
import Logo from "../../assets/hrmslogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faUserCircle,
  faHeart,
  faShoppingCart,
  faBars,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  Badge,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import Cookies from "js-cookie";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL from "../../config";

const Header = ({ cartItemCount, likedProducts }) => {
  const [showFMCGDropdown, setShowFMCGDropdown] = useState(false);
  const [showITProductsDropdown, setShowITProductsDropdown] = useState(false);
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);
  const [username, setUsername] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();

  useEffect(() => {
    const sessionId = Cookies.get("sessionid");
    const csrfToken = Cookies.get("csrftoken");

    if (sessionId) {
      axios
        .post(
          `${BASE_URL}/api/check-session/`,
          { session_id: sessionId },
          {
            headers: { "X-CSRFToken": csrfToken },
            withCredentials: true,
          }
        )
        .then((response) => {
          if (response.data.username) {
            setUsername(response.data.name + " " + response.data.name2);
          }
        })
        .catch(() => {
          setUsername(null);
        });
    }
  }, []);

  const handleProfileMenuOpen = (event) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => setProfileMenuAnchorEl(null);

  const handleLogout = () => {
    axios
      .post(`${BASE_URL}/api/logout/`, {}, { withCredentials: true })
      .then(() => {
        Cookies.remove("sessionKey");
        localStorage.removeItem("sessionKey");
        localStorage.removeItem("cartItems"); 
        window.location.reload();
      })
      .catch((error) => console.error("Error logging out:", error));
  };

  const toggleDrawer = (open) => () => setDrawerOpen(open);

  const handleSearchChange = async (event) => {
    const query = event.target.value;
    setSearchTerm(query);

    if (query) {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/search/?query=${query}`
        );
        setSearchResults(response.data);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleProductClick = (product) => {
    navigate("/viewProduct", { state: { productid: product.id } });
    setSearchTerm(""); // Clear the search bar
    setSearchResults([]); // Clear the search results
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && searchTerm.trim() !== "") {
      navigate(`/search-results?query=${searchTerm}`); // Navigate to search results page
      setSearchResults([]);
      setSearchTerm("");
      // window.location.reload();
    }
  };
  return (
    <header>
      <div className="header-top">
        <div className="header-logo">
          <img src={Logo} alt="Logo" />
        </div>

        {!isMobile && (
        <div className="search-container" style={{ position: "relative", width: "100%" }}>
        {/* Search Input */}
        <input
          type="text"
          placeholder="Search for products, brands and more"
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyPress={handleKeyPress}
          style={{
            width: "100%",
            padding: "8px",
            fontSize: "16px",
            boxSizing: "border-box",
          }}
        />
      
        {/* Results Dropdown */}
        {searchResults.length > 0 && (
          <div
            className="search-results"
            style={{
              position: "absolute",
              top: "100%", // Position right below the input
              left: 0,
              right: 0,
              backgroundColor: "white",
              border: "1px solid #ccc",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              zIndex: 1000,
              maxHeight: "300px", // Optional: Add scrolling for longer lists
              overflowY: "auto",
            }}
          >
            {searchResults.map((product) => (
              <div
                key={product.id}
                onClick={() => handleProductClick(product)}
                style={{
                  padding: "10px",
                  borderBottom: "1px solid #eee",
                  cursor: "pointer",
                  fontSize: "14px",
                  maxHeight: "300px", 
                  overflowy: "auto",
                }}
              >
                {product.product_name}
              </div>
            ))}
          </div>
        )}
      </div>
       
        )}

        <div className="header-icons">
          <IconButton onClick={handleProfileMenuOpen}>
            <FontAwesomeIcon icon={faUserCircle} />
          </IconButton>
          <Menu
            anchorEl={profileMenuAnchorEl}
            open={Boolean(profileMenuAnchorEl)}
            onClose={handleProfileMenuClose}
          >
            {username ? (
              <>
                <MenuItem onClick={handleProfileMenuClose}>
                  Hey, {username}
                </MenuItem>
                <MenuItem onClick={() => navigate("/ordersummary")}>ordersummary</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </>
            ) : (
              <>
                <MenuItem onClick={handleProfileMenuClose}>
                  Hello There
                </MenuItem>
                <MenuItem onClick={() => navigate("/login")}>Login</MenuItem>
              </>
            )}
          </Menu>
          <a href="/favorite">
            <FontAwesomeIcon icon={faHeart} />
          </a>
          <Badge badgeContent={cartItemCount} color="secondary">
            <a href="/product">
              <FontAwesomeIcon icon={faShoppingCart} />
            </a>
          </Badge>
          {isMobile && (
            <IconButton onClick={toggleDrawer(true)}>
              <FontAwesomeIcon icon={faBars} />
            </IconButton>
          )}
        </div>
      </div>

      {!isMobile && (
        <div className="header-navbar">
          <Link to="/">HOME</Link>
          <div
            className="navbar-dropdown"
            onMouseEnter={() => setShowITProductsDropdown(true)}
            onMouseLeave={() => setShowITProductsDropdown(false)}
          >
            <Link to="/">
              PRODUCTS
              <FontAwesomeIcon icon={faChevronDown} />
            </Link>
            {showITProductsDropdown && (
              <div className="dropdown-menu">
                <Link to="/itProduct">ITPRODUCT</Link>
                <Link to="/automation">AUTOMATION</Link>
                <Link to="/fmcg">FMCG</Link>
                <Link to="/hrms ">HRMS</Link>
                <Link to="/academy">ACADEMYCOURSES</Link>
              </div>
            )}
          </div>
          <Link to="/underconstruction">AUTOMATION</Link>
          <Link to="/underconstruction">HRMS</Link>
          <Link to="/underconstruction">ACADEMY</Link>
          <div
            className="navbar-dropdown"
            onMouseEnter={() => setShowFMCGDropdown(true)}
            onMouseLeave={() => setShowFMCGDropdown(false)}
          >
            <Link to="/">
              FMCG
              <FontAwesomeIcon icon={faChevronDown} />
            </Link>
            {showFMCGDropdown && (
              <div className="dropdown-menu">
                <Link to="/underconstruction">HOMECARE</Link>
                <Link to="/underconstruction">PERSONALCARE</Link>
                <Link to="/underconstruction">AUTOMOBILECARE</Link>
              </div>
            )}
          </div>
          <Link to="/about">ABOUT</Link>
          <Link to="/contact">CONTACT US</Link>
        </div>
      )}

      {/* Drawer for Mobile View */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>
          <ListItem button>
            <ListItemText primary="HOME" onClick={() => navigate("/")} />
          </ListItem>
          <ListItem button>
            <ListItemText primary="IT PRODUCTS" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="AUTOMATION" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="HRMS" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="ACADEMY" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="FMCG" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="ABOUT" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="CONTACT US" />
          </ListItem>
        </List>
      </Drawer>
    </header>
  );
};

export default Header;
